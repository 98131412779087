<template>
  <div>
    <div class="container-fluid">
      <div class="row text-start">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
          <template v-if="$parent.$parent.config.orderingPortalLanguage.loginPage">
            <div class="card-text" v-html="$parent.$parent.config.orderingPortalLanguage.loginPage.title"></div>
            </template>
            <template v-else>
              <h1 class="customFont text-start">Ordering Portal</h1>
            </template>

          <template v-if="$parent.$parent.customLanguage.loginPage">
            <div
              class="card-text"
              v-html="$parent.$parent.customLanguage.loginPage.body"
            ></div>
          </template>
          <template v-else>
            <p>
              To continue, please enter a valid email address.<br />We will send
              you a unique access link via email.
            </p>
          </template>
          <div style="margin-top:20px;">
            <div class="form-group">
              <template v-if="$parent.$parent.config.orderingPortalLanguage.loginPage">
              <label for="contactEmail">{{$parent.$parent.config.orderingPortalLanguage.loginPage.emailBoxTitle}}</label>
              </template>
              <template v-else>
                <label for="contactEmail">Your email</label>
              </template>
              <input
                type="email"
                class="form-control"
                id="contactEmail"
                aria-describedby="emailHelp"
                v-model="email"
              />
              <small id="emailHelp" class="form-text text-muted"
                >You should use the same email address that received the
                invitation for this portal.</small
              >
            </div>
          </div>
          <h5 class="text-danger" v-if="error != null">{{ error }}</h5>
          <button
            class="btn bg-primary text-white float-end"
            v-on:click="attemptLogin"
            v-if="!loading"
          >
            Continue
          </button>
          <div v-if="loading">
            <span class="fa fa-spinner fa-spin float-end" />
          </div>
        </div>
        <div class="col-lg-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "request";
export default {
  name: "LoginWelcome",
  methods: {
    attemptLogin: function() {
      this.loading = true;
      this.error = null;
      var licenceId = window.localStorage.getItem("cat24-licence");
      var fetchWith = function(scope) {
        request(
          {
            url: window.apiUrl + "/contact/" + licenceId + "/login",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: scope.email.trim(),
            }),
            method: "POST",
          },
          function(error, response) {
            if (error) {
              scope.error =
                "Error logging in, cannot connect - <" +
                window.apiUrl.replace("https://", "") +
                ">";
            }
            console.log(response);
            scope.loading = false;
            if (response.statusCode != 200) {
              scope.error = "Please check your email and try again.";
              return;
            }

            scope.$router.push("/login/success");
          }
        );
      };
      fetchWith(this);
    },
  },
  data: function() {
    return {
      loading: false,
      email: "",
      error: null,
    };
  },
};
</script>
