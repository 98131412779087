<template>
<div>
  <template v-if="showDialog">
  <div class="modal fade show"  style="display: block;">
    <div class="modal-dialog modal-xl" >
      <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Cat24 FAQs</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="showDialog = false;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body leftallign">
          <p>Before you contact our support team, please read the following -</p>
    <ul class="leftallign">
<li>Have you tried using another device?</li>
<ul><li>
Older devices may have issues with this website. Trying to use this with a smart phone or tablet could help improve your experience.
</li></ul>
<li>
Have you made sure you’re using the correct email address to login?
</li>
<ul><li>
Only registered eligible emails will work on this portal, please make sure you enter the same email that we used to deliver your invitation to this portal.
</li></ul>
<li>
Have you got a stable internet connection?
</li>
<li>
Have you checked your email spam folder?
</li>
<li>
Have you tried restarting your device?
</li>

<template v-if="'settings' in this.$parent.config" >
  

  <template v-if="this.$parent.config.settings.showSupportFAQ === true">
    <li>For support relating to your device delivery/collection, please contact {{this.$parent.config.settings.supportEmail}}</li>
  </template>

  </template>
  <template v-else>
    <li>For support relating to your device delivery/collection, please contact <a href="mailto:projectsupport@jigsaw24.com">projectsupport@jigsaw24.com</a></li>
  </template>
</ul>
<br/><br/>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="showDialog = false;">Close</button>
      </div>
    </div>
  
    </div>
  </div>
  <div class="modal-backdrop show"></div>
</template>

  <div class="text-start row" style="margin-top:15px;">
    <div class="col-lg-2"></div>
    <div class="col-lg-8">
      <!-- <p><a href="/faqs" style="color:#000080;">If you need help, please view our FAQs here.</a></p> -->
      <template v-if="$parent.config.orderingPortalLanguage.faqLink">
        <div class="card-text" style="display: inline;" v-html="$parent.config.orderingPortalLanguage.faqLink.faqText"></div> <a  v-on:click="showDialog = true;" style="color:#0d6efd; text-decoration: underline; display: inline;">{{ $parent.config.orderingPortalLanguage.faqLink.faqLinkText }}</a>
        <div class="card-text" v-html="$parent.config.orderingPortalLanguage.faqLink.link"></div>
      </template>
      <template v-else>
        <p><a  v-on:click="showDialog = true;" style="color:#000080;">If you need help, please view our FAQs here.</a></p>
      </template>
     
    </div>
    <div class="col-lg-2"></div>
  </div>
</div>
</template>

<script>
export default {
  name: 'FAQs',
  methods : {

  },
 data : function(){
    return {
      showDialog: false
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  a:hover {
  cursor:pointer;
 }

  ul { padding-right: 1.2em; }

.leftallign {
  text-align: left !important;
}


</style>
