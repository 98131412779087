<template>
  <nav class="navbar fixed-top navbar-light bg-light">
    <div class="container-fluid">
      <template
            v-if="$parent.$parent.config.orderingPortalLanguage.navHeadding"
          >
            <div
              class="card-text"
              v-html="
                $parent.$parent.config.orderingPortalLanguage.navHeadding.title
              "
            ></div>
          </template>
          <template v-else>
            <a class="navbar-brand customFont float-start" href="#">Ordering Portal</a>
          </template>
   
    <div class="btn btn-sm text-danger" v-on:click="logout">Sign Out</div>
  </div>
  </nav>
</template>

<script>
export default {
  name: 'NavigationBar',
  methods : {
    logout : function(){
      window.localStorage.removeItem('cat24-token');
      this.$router.push('/login');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
