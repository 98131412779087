const axios = require('axios');
const { default: axiosRetry } = require('axios-retry');

export default {
   async sumo(data) {

    console.log(data)

  const logTime = new Date();
    const logTimeISO = logTime.toISOString();
    data.timestamp = logTimeISO;

    const headers = {
        'Content-Type': 'application/json',
    }

    axiosRetry(axios, {
      retries: 1,
      retryDelay: (retryCount) => {
        console.log (`retry attempt: ${retryCount}`);
        return retryCount * 500; // time interval between retries
      },
      retryCondition: (error) => {
        return error.response.status != 200;
      }
        });

    const result = await axios.post( "https://endpoint1.collection.eu.sumologic.com/receiver/v1/http/ZaVnC4dhaV2bfRqNAJ0MwHLPav3xSLcY7-2QPcgC2RVUBFP5dTVrKQDjO0-BXePzVaCVUWGL9fOdUFa3ZyR8pnCTPLjmy6VbeEKVTtZjdFFhFDP9wxT0Tw==", data, {
        headers: headers
      })
   
   return result;
  },
};