import Vue from "vue";
import VueRouter from "vue-router";

require("bootstrap/dist/css/bootstrap.min.css");
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
//import "font-awesome-webpack";

window.__vue = Vue;

if (process.env.VUE_APP_ENV == "dev") {
  window.apiUrl = "https://dev-catcoreapi.j24.io";
  window.apiV2url = "https://dev-cat24api.j24devops.com";
} else {
  window.apiUrl = "https://core.cat24.j24dev.uk";
  window.apiV2url = "https://cat24api.j24.io";
}

Vue.use(VueRouter);

import Home from "./Pages/Home.vue";
import App from "./App.vue";
import NewOrder from "./Pages/Home/NewOrder.vue";
import Login from "./Pages/Login.vue";
import FAQsView from "./Pages/FAQs/FAQsView.vue";
import OrderRouter from "./Pages/Order/OrderRouter.vue";
//import OrderValidation from './Pages/Order/OrderValidation.vue';
import ExistingDevice from "./Pages/Order/ExistingDevice.vue";
import OrderConfirmation from "./Pages/Order/OrderConfirmation.vue";
import OrderComplete from "./Pages/Order/OrderComplete.vue";
import LoginWelcome from "./Pages/Login/Welcome.vue";
import LoginSuccess from "./Pages/Login/Success.vue";
import AcceptPolicy from "./Pages/Policy/AcceptPolicy.vue";
// import About from './components/About.vue';
// import Contact from './components/Contact.vue';

const router = new VueRouter({
  mode: "history",
  base: __dirname,
  routes: [
    {
      path: "/",
      component: Home,
      children: [
        {
          path: "",
          component: NewOrder,
        },
        {
          path: "order/:groupId/:choiceId",
          component: OrderRouter,
          children: [
            // {
            //   path: '', component: OrderValidation
            // },
            {
              path: "existingdevice",
              component: ExistingDevice,
            },
            {
              path: "confirmation",
              component: OrderConfirmation,
            },
            {
              path: "complete",
              component: OrderComplete,
            },
          ],
        },
        {
          path: "policy/:policyId",
          component: AcceptPolicy,
        },
      ],
    },
    { path: "/faqs", component: FAQsView },
    {
      path: "/login",
      component: Login,
      children: [
        {
          path: "",
          component: LoginWelcome,
        },
        {
          path: "success",
          component: LoginSuccess,
        },
      ],
    },
  ],
});

new Vue({
  router: router,
  render: function(createElement) {
    window.appRoot = createElement(App);
    return window.appRoot;
  },
}).$mount("#app");
