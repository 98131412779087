<template>
  <div>
    <template v-if="loading != true">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8 text-start"><LogoView/></div>
      <div class="col-md-2"></div>
    </div>
      <div class="row text-start">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <h5 class="text-muted" v-if="groups.length == 0">We couldn't find any available options for you. If you've already created an order, please wait for email confirmation.</h5>
          <div class="card bg-light" v-if="groups.length > 0">
            <div class="card-header customFont">
              <template v-if="$parent.$parent.config.orderingPortalLanguage.NewOrder">
                <div class="card-text" v-html="$parent.$parent.config.orderingPortalLanguage.NewOrder.headding"></div>
                </template>
                <template v-else>
                  Please select an option from below
                </template>
            </div>
            <div class="card-body" v-for="group in groups" v-bind:key="group.objectId">
              <h5 class="card-title">{{group.name}}</h5>
              <ul class="list-group">
                <li class="list-group-item list-group-item-action" style="cursor:pointer;" v-on:click="clearOrder(choice, group);navigateTo('/order/' + group.objectId + '/' + choice.objectId);" v-for="choice in group.availableChoices" v-bind:key="choice.objectId">
                  <div class="row">
                    <div class="col-md-3"><img v-if="choice.image != null"  height="150px" :src="urlForChoiceImage(choice)"/></div>
                    <div class="col-md-9">
                      <div class="row"><h5>{{choice.name}}</h5></div>
                      <div class="row" v-if="choice.choiceDescription != null"><MarkdownViewer :fileToRender="urlForChoiceDescription(choice)"></MarkdownViewer></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </template>

      <template v-if="loading == true">
        <div class="row">
          <div class="col-sm"></div>
          <div class="col-sm"><LogoView/></div>
          <div class="col-sm"></div>
        </div>
        <div class="row">
          <div class="col-md"></div>
          <div class="col-md">
            <div class="card defaultMargin">
              <div class="card-header">
                Please Wait
              </div>
              <div class="card-body">
                <h5 class="card-title">Fetching your options</h5>
                <p class="card-text">Please wait, we're just finding your available options.</p>
              </div>
            </div>
          </div>
          <div class="col-md"></div>
        </div>
      </template>
  </div>
</template>

<script>
import LogoView from '../../Components/LogoView.vue'
import MarkdownViewer from '../../Components/MarkdownViewer.vue'
import request from 'request';
export default {
  name: 'NewOrder',
  components : {
    LogoView,
    MarkdownViewer
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    navigateTo: function(path) {
      this.$router.push(path);
    },
    clearOrder: function(choice, group) {
      window.localStorage.removeItem("cat24-verified-imei-" + group.objectId + choice.objectId);
      window.localStorage.removeItem("cat24-completed-order-" + group.objectId + choice.objectId);
      window.localStorage.setItem("cat24-current-selection", choice.name);
    },
    urlForChoiceImage : function(choice) {
      if (choice.image == null) {
        return "";
      }
      if (choice.image.url == null) {
        return "";
      }
      return choice.image.url;
    },
    urlForChoiceDescription : function(choice) {
      if (choice.choiceDescription == null) {
        return "";
      }
      if (choice.choiceDescription.url == null) {
        return "";
      }
      return choice.choiceDescription.url;
    },
    fetchChoices: function(){
      this.loading = true;
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/contact/" + window.localStorage.getItem('cat24-licence') + "/choices",
          headers : {
            "x-cat24-contact-token" : window.localStorage.getItem('cat24-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){
            window.localStorage.removeItem('cat24-token');
            scope.$router.push('/login');
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            window.localStorage.removeItem('cat24-token');
            scope.$router.push('/login');
            return
          }
          if (jsonObject != null){
            if (jsonObject.groups != null){
              scope.groups = jsonObject.groups;
              return
            }
            window.localStorage.removeItem('cat24-token');
            scope.$router.push('/login');
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      groups : [],
      loading : false
    }
  },
  created : function(){
    this.fetchChoices();
  }
}
</script>
