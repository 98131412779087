<template>
  <div>
    <div class="container-fluid">
      <div class="row text-start">
        <div class="col-lg-2"></div>
        <div class="col-lg-8 padding">
          <template
            v-if="$parent.$parent.config.orderingPortalLanguage.loginPage"
          >
            <div
              class="customFont card-text"
              v-html="
                $parent.$parent.config.orderingPortalLanguage.loginPage.title
              "
            ></div>
          </template>
          <template v-else>
            <h1 class="customFont text-start">Ordering Portal</h1>
          </template>

          <template
            v-if="$parent.$parent.config.orderingPortalLanguage.SuccessPage"
          >
            <div
              class=""
              v-html="
                $parent.$parent.config.orderingPortalLanguage.SuccessPage.body
              "
            ></div>
          </template>
          <template v-else>
            <p>
              We have emailed you. Please make sure you check your spam folder.
            </p>
            <p>Please allow up to 15 minutes for this to arrive.</p>
            <p>You can now close this window.</p>
          </template>
        </div>
        <div class="col-lg-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginSuccess",
  methods: {},
  data: function () {
    return {};
  },
};
</script>
<style scoped>

.padding {
  margin-left: -7px !important;
}

</style>