<template>
  <div>
      <div class="row text-start">
        <div class="col-lg-2"></div>
      <div class="col-lg-8">
      <LogoView/>
    </div>
        <div class="col-lg-2"></div>
    </div>
    <div class="row text-start">
        <div class="col-lg-2"></div>
      <div class="col-lg-8 leftallign">
        <h1 class="customFont text-start">FAQs</h1>
        <p>Before you contact our support team, please read the following -</p>
    <ul >
<li>Have you tried using another device?</li>
<ul><li>
Older devices may have issues with this website. Trying to use this with a smart phone or tablet could help improve your experience.
</li></ul>
<li>
Have you made sure you’re using the correct email address to login?
</li>
<ul><li>
Only registered eligible emails will work on this portal, please make sure you enter the same email that we used to deliver your invitation to this portal.
</li></ul>
<li>
Have you got a stable internet connection?
</li>
<li>
Have you checked your email spam folder?
</li>
<li>
Have you tried restarting your device?
</li>
</ul>
If you are still experiencing issues, you can contact our support team using the support email address provided in your invitation email
<br/><br/>
<template v-if="supportLink() != null">
For more support visit <a :href="supportLink()">{{supportLink()}}</a>
</template>
        <p> <a href="javascript:history.back()">Back To Order</a></p>
    </div>
    <div class="col-lg-2"></div>
  </div>
  </div>
</template>

<script>

import LogoView from '../../Components/LogoView.vue';
export default {
  name: 'FAQsView',
  components : {
      LogoView
  },
  methods : {
    supportLink: function(){
      return this.$parent.config.supportLink;
    }
  },
  data : function() {
    return {
    }
  }
}
</script>

<style scoped>

ul { padding-right: 1.2em; 
   text-align: left !important;}

li {
  text-align: left !important;
}

.leftallign {
  text-align: left !important;
}

</style>
