<template>
  <div>
    <div class="row text-start">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <LogoView/>
      </div>
      <div class="col-lg-2"></div>
    </div>
    <div class="row text-start">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">

        <template v-if="$parent.$parent.$parent.customLanguage.summeryPage && $parent.$parent.$parent.customLanguage.summeryPage.hedding ">
          <h1 class="customFont text-start">{{ $parent.$parent.$parent.customLanguage.summeryPage.hedding}}</h1>
        </template>
        <template v-else>
          <h1 class="customFont text-start">Order Summary</h1>
        </template>

       
        <template v-if="$parent.$parent.$parent.customLanguage.summeryPage && $parent.$parent.$parent.customLanguage.summeryPage.body ">
          <p><b>{{ $parent.$parent.$parent.customLanguage.summeryPage.body}}</b> - {{currentSelection()}}.</p>
        </template>
        <template v-else>
          <p><b>You have chosen</b> - {{currentSelection()}}.</p>
        </template>

       

        <template v-if="$parent.$parent.$parent.config.settings && $parent.$parent.$parent.config.settings.hideConfirmCheckBox === true">
                  <template v-if="$parent.$parent.$parent.customLanguage.OrderConfirmation && $parent.$parent.$parent.customLanguage.OrderConfirmation.body">
            <div v-html="$parent.$parent.$parent.customLanguage.OrderConfirmation.body"></div>
          </template>
          <template v-else>
          <p>To confirm this selection, please review and accept the terms below, and click Complete Order.</p>
        </template>
        </template>
        <template v-else>

          <template v-if="$parent.$parent.$parent.customLanguage.OrderConfirmation && $parent.$parent.$parent.customLanguage.OrderConfirmation.body">
            <div v-html="$parent.$parent.$parent.customLanguage.OrderConfirmation.body"></div>
          </template>
          <template v-else>
          <p>To confirm this selection, please review and accept the terms below, and click Complete Order.</p>
        </template>
        </template>

          <template v-if="$parent.$parent.$parent.customLanguage.OrderConfirmation">
            <div v-html="$parent.$parent.$parent.customLanguage.OrderConfirmation.terms"></div>
          </template>
          <template v-else>
          <p>For iPhone/iPad deliveries, please be aware that missing or faulty deliveries/goods must be reported to Jigsaw24 within 7 days of the delivery date.</p>

<p>For all Phone/iPad returns, please ensure "Find My iPhone" or Google/Samsung Knox accounts are disabled.</p>
</template>
<template v-if="$parent.$parent.$parent.config.settings && $parent.$parent.$parent.config.settings.hideConfirmCheckBox && $parent.$parent.$parent.config.settings.hideConfirmCheckBox === true">
  <div>
    <p></p>
  </div>
</template>
<template v-else>
  <div class="form-check" style="margin-top:10px;margin-bottom:10px;">
    <template v-if="$parent.$parent.$parent.config.settings && $parent.$parent.$parent.customLanguage.OrderConfirmation && $parent.$parent.$parent.customLanguage.OrderConfirmation.checkBoxlabel">
          <label type="form-check-label" for="fmiAcceptance">{{$parent.$parent.$parent.customLanguage.OrderConfirmation.checkBoxlabel}}</label>
    </template >
    <template v-else>
        <label type="form-check-label" for="fmiAcceptance">Please tick to confirm your acceptance of the above.</label>

    </template>
 
          <input type="checkbox" class="form-check-input" id="fmiAcceptance" value="true"  v-model="fmiAcceptance"/>
        </div>
</template>
        <form>
          <div v-if="loading"><span class="fa fa-spinner fa-spin float-end"/></div>
          <h5 v-if="error != null" class="text-danger">{{error}}</h5>

          <template v-if="$parent.$parent.$parent.config.settings && $parent.$parent.$parent.customLanguage.summeryPage && $parent.$parent.$parent.customLanguage.summeryPage.confirmButtonText">
            <input type="button" class="btn btn-success float-end" v-if="!loading" v-on:click="validateImei()" :value=$parent.$parent.$parent.customLanguage.summeryPage.confirmButtonText :disabled="!fmiAcceptance">
        </template>
        <template v-else>
          <input type="button" class="btn btn-success float-end" v-if="!loading" v-on:click="validateImei()" value="Complete Order" :disabled="!fmiAcceptance">
        </template>

        

          <div class="btn btn-warning float-start" v-if="!loading && $parent.$parent.$parent.customLanguage.OrderConfirmation && $parent.$parent.$parent.customLanguage.OrderConfirmation.hideEdit !== true" v-on:click="navigateTo('/')" >Edit Selection</div>
        </form>
      </div>
      <div class="col-lg-2"></div>
    </div>
  </div>
</template>

<script>
import request from 'request';
import LogoView from '../../Components/LogoView.vue';
import loging from '../../Components/loging.js';
export default {
  name: 'OrderConfirmation',
  components : {
    LogoView
  },
  mounted: function () {
    if (this.$parent.$parent.$parent.config.settings){
      if (this.$parent.$parent.$parent.config.settings.hideConfirmCheckBox && this.$parent.$parent.$parent.config.settings.hideConfirmCheckBox === true){
        this.fmiAcceptance = true
      }
  }

  },
  methods : {
    navigateTo: function(path) {
      this.$router.push(path);
    },
    currentSelection: function(){
      return window.localStorage.getItem("cat24-current-selection");
    },
    validateImei : function(){
      this.loading = true;
      this.error = null;
      var licenceId =  window.localStorage.getItem("cat24-licence");
      var fetchWith = async function(scope) {

         await loging.sumo({"page": "OrderConfirmation","licence": window.localStorage.getItem("cat24-licence"),  "token": window.localStorage.getItem('cat24-token'), "choiceIdentifier": scope.$route.params.choiceId, "choiceGroupIdentifier" : scope.$route.params.groupId, "itemOrdered": window.localStorage.getItem("cat24-current-selection")  })
        request({
    
          url: window.apiUrl + "/contact/" + licenceId + "/orders",
          headers : {
              "x-cat24-contact-token" : window.localStorage.getItem('cat24-token'),
          "Content-Type" : "application/json"
        },
        body : JSON.stringify({
          "choiceIdentifier" : scope.$route.params.choiceId,
          "choiceGroupIdentifier" : scope.$route.params.groupId
        }),
          method : "POST"
        }, function(error, response, data){
          if (response.statusCode != 200){
            scope.loading = false;
            scope.error = "We couldn't create an order right now. Please try again later.";
            return
          } 

          window.localStorage.setItem("cat24-completed-order-" + scope.$route.params.groupId + scope.$route.params.choiceId, true);
          if (data != null) {
            try {
              var responseObject = JSON.parse(data);
              if (responseObject.deliveryPortalAccessLink != null) {
                window.open(responseObject.deliveryPortalAccessLink, "_blank");
              }
            }catch {
              console.log("No redirects needed.")
            }
          }
          scope.$router.push('complete');
        });
      }
      fetchWith(this);
    }
  },
  data : function() {
    return {
      error : null,
      loading : false,
      fmiAcceptance: false
    }
  }
}
</script>
