<template>
  <VueShowdown :markdown="fileContent"></VueShowdown>
</template>

<script>
import VueShowdown from "vue-showdown";
import request from 'request';
export default {
  name: "App",
  components: VueShowdown,
  data: function() {
    return {
      fileContent: null,
      rawContent: null
    };
  },
  props : ["fileToRender"],
  created: function() {
    //  const fileToRender = `./assets/documentation/general/welcome.md`;
    //const rawContent = ""; // Read the file content using fileToRender
    // this.fileContent = "### marked(rawContent) should get executed";
    this.getContent();
  },
  methods: {
    getContent() {
      var self = this;
      this.fileContent = "rendering ";
      // var self;
      request({
        url: this.fileToRender,
        method : "GET"
      }, function(error, response, body){
        if (response.statusCode != 200){
          self.fileContent = "An error ocurred";
          return
        }
        self.fileContent = body;
      });
    }
  }
};
</script>
