<template>
  <div class="loginWrapper">
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8 text-start"><LogoView/></div>
      <div class="col-lg-2"></div>
    </div>
      <RouterView></RouterView>
  </div>
</template>

<script>
import LogoView from '../Components/LogoView.vue'
export default {
  name: 'Login',
  components : {
    LogoView
  },
  created : function() {
    //If local session token doesnt exist
      //redirect to login
      //this.$router.push('/login');
      if (window.localStorage.getItem('cat24-token') != null) {
          this.$router.push('/');
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.loginWrapper {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
