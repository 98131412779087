<template>
  <div class="homeWrapper">
    <template v-if="validToken && !loading">
      <NavigationBar></NavigationBar>
      <router-view></router-view>
    </template>
    <template v-if="loading == true">
      <div class="row">
        <div class="col-sm"></div>
        <div class="col-sm"><LogoView /></div>
        <div class="col-sm"></div>
      </div>
      <div class="row">
        <div class="col-md"></div>
        <div class="col-md">
          <div class="card defaultMargin">
            <template v-if="errorState">
              <div class="card-header">ERROR</div>
              <div class="card-body">
                <h5 class="card-title">{{ errorTitle }}</h5>
                <p class="card-text">
                  {{ errorText }}
                </p>
              </div>
            </template>
            <template v-else>
              <div class="card-header">Please Wait</div>
              <div class="card-body">
                <h5 class="card-title">Validating Token</h5>
                <p class="card-text">
                  Please wait, we're just validating your session.
                </p>
              </div>
            </template>
          </div>
        </div>
        <div class="col-md"></div>
      </div>
    </template>
  </div>
</template>

<script>
import NavigationBar from "../Components/NavigationBar.vue";
import LogoView from "../Components/LogoView.vue";
import request from "request";
export default {
  name: "Home",
  components: {
    NavigationBar,
    LogoView,
  },
  methods: {
    fetchPolicies: function () {
      var fetchWith = function (scope) {
        request(
          {
            url:
              window.apiUrl +
              "/contact/" +
              window.localStorage.getItem("cat24-licence") +
              "/policies",
            headers: {
              "x-cat24-contact-token":
                window.localStorage.getItem("cat24-token"),
            },
            method: "GET",
          },
          function (error, response, data) {
            scope.loading = false;
            if (response.statusCode != 200) {
              window.localStorage.removeItem("cat24-token");
              scope.$router.push("/login");
              return;
            }
            var jsonObject = JSON.parse(data);
            if (error != null) {
              window.localStorage.removeItem("cat24-token");
              scope.$router.push("/login");
              return;
            }
            if (jsonObject != null) {
              if (jsonObject.policies != null) {
                var requiredPolicies = jsonObject.policies.filter(function (
                  policy
                ) {
                  return policy.required && !policy.accepted;
                });
                if (requiredPolicies.length > 0) {
                  if (
                    scope.$route.path !=
                    "/policy/" + requiredPolicies[0].objectId
                  ) {
                    scope.$router.push(
                      "/policy/" + requiredPolicies[0].objectId
                    );
                  }
                }
                return;
              }
              window.localStorage.removeItem("cat24-token");
              scope.$router.push("/login");
            }
          }
        );
      };
      fetchWith(this);
    },
    validateToken: function () {
      this.loading = true;
      var fetchWith = function (scope) {
        request(
          {
            url:
              window.apiUrl +
              "/contact/" +
              window.localStorage.getItem("cat24-licence") +
              "/me",
            headers: {
              "x-cat24-contact-token":
                window.localStorage.getItem("cat24-token"),
            },
            method: "GET",
          },
          function (error, response, data) {
            if (error) {
              scope.errorState = true;
              scope.errorTitle = "Unable to validate token";
              scope.errorText =
                "Cannot reach token validator - <" +
                window.apiUrl.replace("https://", "") +
                ">";
              return;
            }
            if (response.statusCode != 200) {
              window.localStorage.removeItem("cat24-token");
              scope.$router.push("/login");
              return;
            }
            var jsonObject = JSON.parse(data);
            if (error != null) {
              window.localStorage.removeItem("cat24-token");
              scope.$router.push("/login");
              return;
            }
            if (jsonObject != null) {
              if (jsonObject.objectId != null) {
                scope.validToken = true;
                scope.fetchPolicies();
                return;
              }
              window.localStorage.removeItem("cat24-token");
              scope.$router.push("/login");
            }
          }
        );
      };
      fetchWith(this);
    },
  },
  data: function () {
    return {
      validToken: false,
      loading: false,
      errorState: false,
      errorTitle: "",
      errorText: "",
    };
  },
  created: function () {
    //If local session token doesnt exist
    //redirect to login
    //this.$router.push('/login');
    if (window.localStorage.getItem("cat24-token") == null) {
      this.$router.push("/login");
      return;
    }
    this.validateToken();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.hello {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
