<template>
  <div>
      <div class="row text-start">
        <div class="col-lg-2"></div>
      <div class="col-lg-8">
      <LogoView/>
    </div>
        <div class="col-lg-2"></div>
    </div>
    <div class="row text-start">
        <div class="col-lg-2"></div>
      <div class="col-lg-8">

        <template v-if="$parent.$parent.$parent.config.orderingPortalLanguage.orderCreated">
            <h1 class="customFont text-start">{{ $parent.$parent.$parent.config.orderingPortalLanguage.orderCreated.headding }}</h1>
            </template>
            <template v-else>
              <h1 class="customFont text-start">Ordering Portal</h1>
            </template>

            <template v-if="$parent.$parent.$parent.config.orderingPortalLanguage.orderCreated">
            <div class="card-text" v-html="$parent.$parent.$parent.config.orderingPortalLanguage.orderCreated.body"></div>
            </template>
            <template v-else>
              <p>Your order has been created. You should receive an email shortly. You can now close this page.</p>
            </template> 

    </div>
    <div class="col-lg-2"></div>
  </div>
  </div>
</template>

<script>

import LogoView from '../../Components/LogoView.vue';
export default {
  name: 'OrderComplete',
  components : {
      LogoView
  },
  methods : {
  },
  data : function() {
    return {
    }
  }
}
</script>
