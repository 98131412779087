var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row text-start"},[_c('div',{staticClass:"col-lg-2"}),_c('div',{staticClass:"col-lg-8"},[_c('LogoView')],1),_c('div',{staticClass:"col-lg-2"})]),_c('div',{staticClass:"row text-start"},[_c('div',{staticClass:"col-lg-2"}),_c('div',{staticClass:"col-lg-8"},[(
          _vm.$parent.$parent.$parent.customLanguage.exisitingItemPage &&
          _vm.$parent.$parent.$parent.customLanguage.exisitingItemPage.headding
        )?[_c('h1',{staticClass:"customFont text-start"},[_vm._v(" "+_vm._s(_vm.$parent.$parent.$parent.customLanguage.exisitingItemPage.headding)+" ")])]:[_c('h1',{staticClass:"customFont text-start"},[_vm._v("Existing Device")])],(
          _vm.$parent.$parent.$parent.customLanguage.exisitingItemPage &&
          _vm.$parent.$parent.$parent.customLanguage.exisitingItemPage.body
        )?[_c('div',{staticClass:"card-text",domProps:{"innerHTML":_vm._s(
            _vm.$parent.$parent.$parent.customLanguage.exisitingItemPage.body
          )}})]:[_c('p',[_vm._v(" We need you to provide details of your existing device that needs to be returned. ")])],_c('form',[_c('div',{staticClass:"form-group"},[(_vm.$parent.$parent.$parent.customLanguage.exisitingItemPage)?[_c('label',{attrs:{"for":"imei"}},[_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.$parent.$parent.$parent.customLanguage.exisitingItemPage
                    .imeiLabel
                )}})])]:[_c('label',{attrs:{"for":"imei"}},[_vm._v("Your existing IMEI Number")])],_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.imei),expression:"imei"}],staticClass:"form-control",attrs:{"type":"text","id":"imei","aria-describedby":"imeiHelp","disabled":_vm.loading},domProps:{"value":(_vm.imei)},on:{"input":function($event){if($event.target.composing){ return; }_vm.imei=$event.target.value}}}),(_vm.$parent.$parent.$parent.customLanguage.exisitingItemPage)?[_c('small',{staticClass:"form-text text-muted",attrs:{"id":"imeiHelp"}},[_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.$parent.$parent.$parent.customLanguage.exisitingItemPage
                    .imeiToolTip
                )}})])]:[_c('small',{staticClass:"form-text text-muted",attrs:{"id":"imeiHelp"}},[_vm._v("You can find this by dialing *#06# on your existing device.")]),_c('small',{staticClass:"form-text text-muted",attrs:{"id":"imeiHelp"}},[_vm._v("If your device is lost or stolen please contact support via our FAQ page.")])]],2),(!_vm.$parent.$parent.$parent.customLanguage.exisitingItemPage)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"conditionRating"}},[_vm._v("Device Condition Estimate")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.conditionRating),expression:"conditionRating"}],staticClass:"form-control",attrs:{"id":"conditionRating","disabled":_vm.loading},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.conditionRating=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("1 - Ok Condition")]),_c('option',{attrs:{"value":"2"}},[_vm._v("2 - Fair Condition")]),_c('option',{attrs:{"value":"3"}},[_vm._v("3 - Poor Condition")]),_c('option',{attrs:{"value":"4"}},[_vm._v("4 - Bad Condition")])]),_c('small',{staticClass:"form-text text-muted",attrs:{"id":"conditionHelp"}},[_vm._v("This rating has no affect on you and is only used to help Jigsaw24 grade devices.")])]):_vm._e(),_c('br'),(
            _vm.$parent.$parent.$parent.customLanguage.exisitingItemPage.noTick !=
            true
          )?[_c('div',{staticClass:"form-check",staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[(_vm.$parent.$parent.$parent.customLanguage.exisitingItemPage)?[_c('label',{attrs:{"type":"form-check-label","for":"fmiAcceptance"}},[_vm._v(_vm._s(_vm.$parent.$parent.$parent.customLanguage.exisitingItemPage.terms))])]:[_c('label',{attrs:{"type":"form-check-label","for":"fmiAcceptance"}},[_vm._v("Please tick to acknowledge the requirement for you to disable Find My iPhone or Google/Samsung Knox accounts to wipe phone.")])],_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fmiAcceptance),expression:"fmiAcceptance"}],staticClass:"form-check-input",attrs:{"type":"checkbox","id":"fmiAcceptance"},domProps:{"checked":Array.isArray(_vm.fmiAcceptance)?_vm._i(_vm.fmiAcceptance,null)>-1:(_vm.fmiAcceptance)},on:{"change":function($event){var $$a=_vm.fmiAcceptance,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.fmiAcceptance=$$a.concat([$$v]))}else{$$i>-1&&(_vm.fmiAcceptance=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.fmiAcceptance=$$c}}}})],2)]:_vm._e(),_c('br'),_c('br'),(_vm.loading)?_c('div',[_c('span',{staticClass:"fa fa-spinner fa-spin float-end"})]):_vm._e(),(_vm.error != null)?_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),(_vm.error != null && _vm.imeiSupportLink() != null)?_c('p',{staticClass:"text-danger"},[_vm._v(" For IMEI support please visit "),_c('a',{attrs:{"href":_vm.imeiSupportLink(),"target":"_blank"}},[_vm._v(_vm._s(_vm.imeiSupportLink()))])]):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"btn btn-primary float-end",on:{"click":function($event){return _vm.validateImei()}}},[_vm._v(" Continue ")]):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"btn btn-warning float-start",on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" Go Back ")]):_vm._e()],2)],2),_c('div',{staticClass:"col-lg-2"})])])}
var staticRenderFns = []

export { render, staticRenderFns }