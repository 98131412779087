<template>
  <div>
    <div class="row text-start">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <div class="card" style="margin-top:20px;" v-if="policyDetails != null">
          <div class="card-header bg-warning">
            Policy Acceptance
          </div>
          <div class="card-body">
            <h3 class="card-title"><u>{{policyDetails.name}}</u></h3>
            <template v-if="!$parent.$parent.config.orderingPortalLanguage.policyPage">
            <p class="card-text">You must accept this policy before you can continue.</p>
          </template>
          <p class="card-text" v-if="policyDocumentUrl() == null"  v-html="$parent.$parent.config.orderingPortalLanguage.policyPage.headding" style="color:red"></p>
              <p class="card-text" v-if="policyUrl() != null">You can view the policy <a target="_blank" :href="policyUrl()">here</a>.</p>
           
              <template v-if="$parent.$parent.config.orderingPortalLanguage.policyPage">
                <div class="card-text" v-if="policyDocumentUrl() != null" v-html="$parent.$parent.config.orderingPortalLanguage.policyPage.headding" style="color:red"></div>
                
              </template>
              <template v-else>
                <p class="card-text" v-if="policyDocumentUrl() != null">Please read the policy and accept at the bottom of the page.</p>
              </template>
            

            <iframe v-if="policyDocumentUrl() != null" width="100%" height="1000px;" :src="policyDocumentUrl()"/>
            <div v-if="loading"><span class="fa fa-spinner fa-spin float-end"/></div>
            <div class="btn btn-success float-end" style="margin-top:10px;" v-on:click="acceptPolicy()" v-if="!loading">Accept Policy</div>
          </div>
        </div>
        <div class="card" style="margin-top:20px;" v-if="policyDetails == null">
          <div class="card-header">
            Please Wait
          </div>
          <div class="card-body">
            <h5 class="card-title">Fetching Policy</h5>
            <p class="card-text">We need you to agree to a policy first.</p>
          </div>
        </div>
    </div>
    <div class="col-lg-2"></div>
</div>
  </div>
</template>

<script>
import request from 'request';
import loging from '../../Components/loging.js';

export default {
  name: 'AcceptPolicy',
  components: {
  },
  methods : {
    policyUrl : function() {
        return this.policyDetails.policyLink;
    },
    policyDocumentUrl : function() {
      if (this.policyDetails.policyDocument != null) {
        return this.policyDetails.policyDocument.url;
      }
      return null;
    },
    acceptPolicy: async function(){
        this.loading = true;
        var licenceId =  window.localStorage.getItem("cat24-licence");
        await loging.sumo(this.logingData)
        var fetchWith = function(scope) {
          request({
            url: window.apiUrl + "/contact/" + licenceId + "/policies/" + scope.$route.params.policyId + "/accept",
            headers : {
                "x-cat24-contact-token" : window.localStorage.getItem('cat24-token')
            },
            method : "POST"
          }, async function(error, response){
            if (response.statusCode != 200){
                scope.loading = false;
              return
            }
            window.location.href = "/";
          });
        }
        fetchWith(this);
    },
    fetchPolicy: function(){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/contact/" + window.localStorage.getItem('cat24-licence') + "/policies/" + scope.$route.params.policyId,
          headers : {
            "x-cat24-contact-token" : window.localStorage.getItem('cat24-token')
          },
          method : "GET"
        }, function(error, response, data){


          scope.loading = false;
          if (response.statusCode != 200){
            window.localStorage.removeItem('cat24-token');
            scope.$router.push('/login');
            return
          }
          var jsonObject = JSON.parse(data);
          scope.logingData = {"page": "policyAccept","licence": window.localStorage.getItem("cat24-licence"), "token": window.localStorage.getItem('cat24-token'), "policyInfo": jsonObject.name }

          if (error != null){
            window.localStorage.removeItem('cat24-token');
            scope.$router.push('/login');
            return
          }
          if (jsonObject != null){
            if (jsonObject.objectId != null){
              scope.policyDetails = jsonObject;
              return
            }


            window.localStorage.removeItem('cat24-token');
            scope.$router.push('/login');
          }
        });
      }
      fetchWith(this);
    }
  },
  created : async function() {

   await this.fetchPolicy();
  },
  data : function() {
    //console.log("Hello");
    return {
      policyDetails : null,
      loading : false,
      logingData: {}
    }
  }
}
</script>
