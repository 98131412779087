<template>
  <div id="app">
    <div class="container-fluid">
      <template v-if="!loading">
        <router-view />
        <FAQs v-if="showFAQs()"></FAQs>
      </template>
      <template v-if="loading == true">
        <div class="row">
          <div class="col-sm"></div>
          <div class="col-sm"><LogoView /></div>
          <div class="col-sm"></div>
        </div>
        <div class="row">
          <div class="col-md"></div>
          <div class="col-md">
            <div class="card defaultMargin">
              <template v-if="errorState">
                <div class="card-header">
                  ERROR
                </div>
                <div class="card-body">
                  <h5 class="card-title">{{ errorTitle }}</h5>
                  <p class="card-text">
                    {{ errorText }}
                  </p>
                </div>
              </template>
              <template v-else>
                <div class="card-header">
                  Please Wait
                </div>
                <div class="card-body">
                  <h5 class="card-title">Fetching configuration.</h5>
                  <p class="card-text">
                    Please wait, we're just fetching some information.
                  </p>
                </div>
              </template>
            </div>
          </div>
          <div class="col-md"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import FAQs from "./Components/Faqs.vue";
import LogoView from "./Components/LogoView.vue";
import request from "request";

export default {
  name: "App",
  components: {
    FAQs,
    LogoView,
  },
  methods: {
    showFAQs: function() {
      if (this.$route.path == "/faqs") {
        return false;
      }
      return true;
    },
    fetchConfig: function() {
      this.loading = true;
      if (!window.localStorage.getItem("cat24-licence")) {
        this.errorTitle = "Invalid Configuration";
        this.errorText =
          "Please make sure the address you are visiting is correct.";
        this.errorState = true;
        return;
      }

      var fetchWith = function(scope) {
        request(
          {
            headers: {
              "x-api-key": "6l3hMtOCKU1qhIcR0LqiZa3PSjxW8qg18EG9wSad",
            },
            url:
              window.apiV2url +
              "/contact/" +
              window.localStorage.getItem("cat24-licence") +
              "/config",
            method: "GET",
          },
          function(error, response, data) {
            if (error) {
              scope.errorTitle = "Failed to obtain configuration.";
              scope.errorText =
                "Please make sure the address you are visiting is correct. <" +
                window.localStorage.getItem("cat24-licence") +
                "-" +
                window.apiV2url.replace("https://", "") +
                ">";
              scope.errorState = true;
              return;
            }
            try {
              var jsonObject = JSON.parse(data);
              scope.config = jsonObject;
              scope.loading = false;
              scope.customLanguage = jsonObject.orderingPortalLanguage;
            } catch (error) {
              console.log(error);
            }

            console.log(error);
          }
        );
      };
      fetchWith(this);
    },
  },
  data: function() {
    return {
      projects: [],
      currentProject: null,
      loading: false,
      config: null,
      customLanguage: "",
      customfaq: [],
      noConfiguration: false,
      errorText: "",
      errorTitle: "",
      errorState: false,
    };
  },
  created: async function() {
    if (this.$route.query.t != null) {
      window.localStorage.setItem("cat24-token", this.$route.query.t);
      console.log("Saved Token.");
    }
    if (this.$route.query.l != null) {
      window.localStorage.setItem("cat24-licence", this.$route.query.l);
      console.log("Saved LicenceId.");
    }

    //   var faqUrl = 'https://x2lkx1cfjg.execute-api.eu-west-2.amazonaws.com/main/customfqaprod'
    // if (process.env.VUE_APP_ENV == "dev"){
    //    faqUrl = 'https://x2lkx1cfjg.execute-api.eu-west-2.amazonaws.com/main/customfqa'
    // }

    //      var FAQconfig = {
    //   method: 'get',
    //   url: faqUrl,
    //   headers: {
    //     'X-Cat24-Contact-Token':  window.localStorage.getItem('cat24-token'),
    //     'X-Cat24-Contact-License':window.localStorage.getItem("cat24-licence")
    //   }
    // };

    //     var cstomFaqData =  await axios(FAQconfig)

    //        this.customfaq = JSON.parse(cstomFaqData.data.body);

    this.fetchConfig();
  },
};
</script>

<style>
.primaryText {
  color: #000;
}
.secondaryText {
  font-size: 16px;
  color: #777;
}
.defaultPadding {
  padding: 15px;
}
.defaultMargin {
  margin: 15px;
}
.activeLink {
  color: blue !important;
  font-weight: 500 !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.customFont {
  font-family: "Helvetica" !important;
}
</style>
