<template>
  <div>
    <div class="row text-start">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <LogoView />
      </div>
      <div class="col-lg-2"></div>
    </div>
    <div class="row text-start">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <template
          v-if="
            $parent.$parent.$parent.customLanguage.exisitingItemPage &&
            $parent.$parent.$parent.customLanguage.exisitingItemPage.headding
          "
        >
          <h1 class="customFont text-start">
            {{
              $parent.$parent.$parent.customLanguage.exisitingItemPage.headding
            }}
          </h1>
        </template>
        <template v-else>
          <h1 class="customFont text-start">Existing Device</h1>
        </template>
        <template
          v-if="
            $parent.$parent.$parent.customLanguage.exisitingItemPage &&
            $parent.$parent.$parent.customLanguage.exisitingItemPage.body
          "
        >
          <div
            class="card-text"
            v-html="
              $parent.$parent.$parent.customLanguage.exisitingItemPage.body
            "
          ></div>
        </template>
        <template v-else>
          <p>
            We need you to provide details of your existing device that needs to
            be returned.
          </p>
        </template>

        <form>
          <div class="form-group">
            <template
              v-if="$parent.$parent.$parent.customLanguage.exisitingItemPage"
            >
              <label for="imei"
                ><div
                  v-html="
                    $parent.$parent.$parent.customLanguage.exisitingItemPage
                      .imeiLabel
                  "
                ></div
              ></label>
            </template>
            <template v-else>
              <label for="imei">Your existing IMEI Number</label>
            </template>
            <input
              type="text"
              class="form-control"
              id="imei"
              aria-describedby="imeiHelp"
              v-model="imei"
              :disabled="loading"
            />
            <template
              v-if="$parent.$parent.$parent.customLanguage.exisitingItemPage"
            >
              <small id="imeiHelp" class="form-text text-muted"
                ><div
                  v-html="
                    $parent.$parent.$parent.customLanguage.exisitingItemPage
                      .imeiToolTip
                  "
                ></div
              ></small>
            </template>
            <template v-else>
              <small id="imeiHelp" class="form-text text-muted"
                >You can find this by dialing *#06# on your existing
                device.</small
              >
              <small id="imeiHelp" class="form-text text-muted"
                >If your device is lost or stolen please contact support via our
                FAQ page.</small
              >
            </template>
          </div>
          <div
            class="form-group"
            v-if="!$parent.$parent.$parent.customLanguage.exisitingItemPage"
          >
            <label for="conditionRating">Device Condition Estimate</label>
            <select
              class="form-control"
              id="conditionRating"
              v-model="conditionRating"
              :disabled="loading"
            >
              <option value="1">1 - Ok Condition</option>
              <option value="2">2 - Fair Condition</option>
              <option value="3">3 - Poor Condition</option>
              <option value="4">4 - Bad Condition</option>
            </select>
            <small id="conditionHelp" class="form-text text-muted"
              >This rating has no affect on you and is only used to help
              Jigsaw24 grade devices.</small
            >
          </div>
          <br />
          <template
            v-if="
              $parent.$parent.$parent.customLanguage.exisitingItemPage.noTick !=
              true
            "
          >
            <div
              class="form-check"
              style="margin-top: 10px; margin-bottom: 10px"
            >
              <template
                v-if="$parent.$parent.$parent.customLanguage.exisitingItemPage"
              >
                <label type="form-check-label" for="fmiAcceptance">{{
                  $parent.$parent.$parent.customLanguage.exisitingItemPage.terms
                }}</label>
              </template>
              <template v-else>
                <label type="form-check-label" for="fmiAcceptance"
                  >Please tick to acknowledge the requirement for you to disable
                  Find My iPhone or Google/Samsung Knox accounts to wipe
                  phone.</label
                >
              </template>
              <input
                type="checkbox"
                class="form-check-input"
                id="fmiAcceptance"
                v-model="fmiAcceptance"
              />
            </div>
          </template>
          <br /><br />
          <div v-if="loading">
            <span class="fa fa-spinner fa-spin float-end" />
          </div>
          <p v-if="error != null" class="text-danger">{{ error }}</p>
          <p
            v-if="error != null && imeiSupportLink() != null"
            class="text-danger"
          >
            For IMEI support please visit
            <a :href="imeiSupportLink()" target="_blank">{{
              imeiSupportLink()
            }}</a>
          </p>
          <div
            class="btn btn-primary float-end"
            v-if="!loading"
            v-on:click="validateImei()"
          >
            Continue
          </div>
          <div
            class="btn btn-warning float-start"
            v-if="!loading"
            v-on:click="goBack()"
          >
            Go Back
          </div>
        </form>
      </div>
      <div class="col-lg-2"></div>
    </div>
  </div>
</template>

<script>
import request from "request";
import LogoView from "../../Components/LogoView.vue";
export default {
  name: "ExistingDevice",
  components: {
    LogoView,
  },
  mounted: function () {
    if (this.$parent.$parent.$parent.customLanguage.exisitingItemPage) {
      this.conditionRating =
        this.$parent.$parent.$parent.customLanguage.exisitingItemPage.defaultConfition;
    }
  },
  methods: {
    goBack: function () {
      this.$router.push("/");
    },
    imeiSupportLink: function () {
      return this.$parent.$parent.$parent.config.imeiSupportLink;
    },
    validateImei: function () {
      if (
        this.$parent.$parent.$parent.customLanguage.exisitingItemPage.noTick ===
        true
      ) {
        this.fmiAcceptance = true;
      }

      if (
        this.$parent.$parent.$parent.customLanguage.exisitingItemPage
          .imeiOptional === true
      ) {
        this.imei = "Not Given";
      }

      if (this.fmiAcceptance == false) {
        this.error = "You must tick to acknowledge the above.";
        return;
      }
      if (this.conditionRating == "") {
        this.error = "You must provide a device condition estimate above.";
        return;
      }

      if (this.imei == "") {
        this.error = "You must provide a IMEI number.";
        return;
      }

      console.log("====");
      console.log(this.$parent.$parent.$parent.config.recordImei);

      if (this.$parent.$parent.$parent.config.recordImei) {
        this.recordImei = this.$parent.$parent.$parent.config.recordImei;
      }

      this.loading = true;
      this.error = null;
      var licenceId = window.localStorage.getItem("cat24-licence");
      var fetchWith = async function (scope) {
        await request(
          {
            headers: {
              "x-api-key": "6l3hMtOCKU1qhIcR0LqiZa3PSjxW8qg18EG9wSad",
              "X-Cat24-Contact-Token":
                window.localStorage.getItem("cat24-token"),
            },
            body: {
              existingDevice: scope.imei,
              condition: scope.conditionRating,
              recordImei: scope.recordImei,
            },
            json: true,
            url: window.apiV2url + "/contact/" + licenceId + "/devicecheck",
            method: "POST",
          },
          function (error, response, data) {
            console.log("===");
            console.log(data.deviceMatch);

            var responce = data;

            if (responce.deviceMatch === false) {
              scope.loading = false;
              scope.error =
                "The IMEI number you entered is not what we have on record for you. Please check it and try again.";
              return;
            }

            if (responce.deviceMatch === "error") {
              scope.loading = false;
              scope.error =
                "We dont have an existing device on record from you, please contact support.";
              return;
            }
            window.localStorage.setItem(
              "cat24-verified-imei-" +
                scope.$route.params.groupId +
                scope.$route.params.choiceId,
              true
            );
            scope.$router.push("confirmation");
          }
        );
      };

      if (
        this.$parent.$parent.$parent.customLanguage.exisitingItemPage
          .imeiOptional === true
      ) {
        window.localStorage.setItem(
          "cat24-verified-imei-" +
            this.$route.params.groupId +
            this.$route.params.choiceId,
          true
        );
        this.$router.push("confirmation");
      } else {
        fetchWith(this);
      }
    },
  },
  data: function () {
    return {
      imei: "",
      conditionRating: "",
      error: null,
      loading: false,
      fmiAcceptance: false,
      recordImei: false,
    };
  },
};
</script>
