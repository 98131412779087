<template>
  <div>
    <template v-if="!loading">
      <router-view></router-view>
    </template>
    <template v-if="loading == true">
      <div class="row">
        <div class="col-md"></div>
        <div class="col-md">
          <div class="card defaultMargin">
            <div class="card-header">
              Please Wait
            </div>
            <div class="card-body">
              <h5 class="card-title">Validating Order</h5>
              <p class="card-text">Please wait, we're just validating your order requirements.</p>
            </div>
          </div>
        </div>
        <div class="col-md"></div>
      </div>
    </template>
  </div>
</template>

<script>
import request from 'request';
export default {
  name: 'OrderRouter',
  components : {
  },
  methods : {
    validateOrder: function(){
      this.loading = true;
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/contact/" + window.localStorage.getItem('cat24-licence') + "/choices/" + scope.$route.params.choiceId,
          headers : {
            "x-cat24-contact-token" : window.localStorage.getItem('cat24-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){
            window.localStorage.removeItem('cat24-token');
            scope.$router.push('/login');
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            window.localStorage.removeItem('cat24-token');
            scope.$router.push('/login');
            return
          }
          if (jsonObject != null){
            if (jsonObject.objectId != null){
              //Validate and perform redirect here...
              var lastItem = scope.$route.path.substring(scope.$route.path.lastIndexOf('/') + 1);
              var pathLength = scope.$route.path.split('/').length - 1;

              if (jsonObject.returnExistingDevice == true && window.localStorage.getItem("cat24-verified-imei-" + scope.$route.params.groupId + scope.$route.params.choiceId) == null) {
                if (lastItem != "existingdevice"){
                  if (pathLength > 3) {
                    this.validateImei = jsonObject.validateImei
                      scope.$router.push('existingdevice');
                  }else {
                    scope.$router.push(scope.$route.path + '/existingdevice');
                  }
                }
                return
              }

              if (lastItem != "confirmation" && window.localStorage.getItem("cat24-completed-order-" + scope.$route.params.groupId + scope.$route.params.choiceId) == null){
                if (pathLength > 3) {
                    scope.$router.push('confirmation');
                }else {
                  scope.$router.push(scope.$route.path + '/confirmation');
                }
                return
              }else {
                if (lastItem != "complete" && window.localStorage.getItem("cat24-completed-order-" + scope.$route.params.groupId + scope.$route.params.choiceId) != null){
                  if (pathLength > 3) {
                      scope.$router.push('complete');
                  }else {
                    scope.$router.push(scope.$route.path + '/complete');
                  }
                }
              }
              return
            }
            window.localStorage.removeItem('cat24-token');
            scope.$router.push('/login');
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function() {
    return {
      loading : true,
      validateImei: ""
    }
  },
  created : function() {
    this.validateOrder();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
