<template>
  <div>
  <img v-if="needsJigsawLogo()" src="../assets/logo.png" alt="Jigsaw24 Logo" width="50%" height="auto" style="margin-bottom:50px;"/>
  <img v-if="!needsJigsawLogo()" :src="logoSrc()" alt="Project Logo" width="20%" height="auto" style="margin-bottom:50px;"/>
  </div>
</template>

<script>
export default {
  name: 'LogoView',
  methods : {
    needsJigsawLogo : function(){
      return window.localStorage.getItem('cat24-licence') == null;
    },
    logoSrc : function(){
      return window.apiUrl + "/licence/" + window.localStorage.getItem('cat24-licence') + "/logo";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
